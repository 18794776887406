import React from 'react';
import PropTypes from 'prop-types';

import ShopifyMetafieldText from '../ShopifyMetafieldText';

const DynamicShopifyMetafieldText = ({ blok, ...rest }) => (
  <ShopifyMetafieldText {...blok} {...rest} />
);

DynamicShopifyMetafieldText.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default DynamicShopifyMetafieldText;
