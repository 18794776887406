// Displays content from the collection/product metafield
import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';

import { findMetafieldValue } from '../lib/product';
import MarkdownContent from './MarkdownContent';

const ShopifyMetafieldText = ({
  collection,
  product,
  fieldKey,
  fieldNamespace,
  disableForceBlock,
  size = 'medium',
  as,
}) => {
  const source = collection || product;
  if (!source) return null;
  const content = findMetafieldValue(
    fieldKey,
    source.metafields,
    fieldNamespace
  );

  return (
    <Box>
      <MarkdownContent
        forceBlock={disableForceBlock ? false : true}
        size={size}
      >
        {content}
      </MarkdownContent>
    </Box>
  );
};

ShopifyMetafieldText.propTypes = {
  collection: PropTypes.object,
  product: PropTypes.object,
  fieldKey: PropTypes.string.isRequired,
  fieldNamespace: PropTypes.string,
  size: PropTypes.string,
};

export default ShopifyMetafieldText;
